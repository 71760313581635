import $ from "jquery";
import 'lightbox2';
import '../../global/js/cms.js';
import flatpickr from "../../node_modules/flatpickr/dist/flatpickr";
import { Polish } from "../../node_modules/flatpickr/dist/l10n/pl.js"

$(document).ready(function() {
    flatpickr("#birthday", {
        'locale': Polish,
        dateFormat: "Y-m-d",
        allowInput: true
    });

    flatpickr("#arrival", {
        'locale': Polish,
        dateFormat: "Y-m-d",
        minDate: "2024-08",
        enable: [
            {
                from: "2024-08-05",
                to: "2024-08-11"
            },
        ]
    });

    flatpickr("#departure", {
        'locale': Polish,
        dateFormat: "Y-m-d",
        minDate: "2024-08",
        enable: [
            {
                from: "2024-08-05",
                to: "2024-08-11"
            },
        ]
    });
});

